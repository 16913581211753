import HeaderNavigation from "./header-navigation";
import PropTypes from "prop-types";

const FrameComponent2 = ({ className = "" }) => {
  return (
    <header
      className={`self-stretch flex flex-row items-start justify-center pt-0 pb-[33px] pr-0 pl-1.5 box-border max-w-full text-left text-xl text-crimson-200 font-small-text ${className}`}
    >
      <div className="w-[724px] overflow-hidden shrink-0 flex flex-row items-start justify-center py-2.5 px-0 box-border max-w-full">
        <HeaderNavigation />
      </div>
    </header>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
