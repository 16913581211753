import PropTypes from "prop-types";

const FrameComponent1 = ({ className = "" }) => {
  return (
    <section
      className={`flex flex-col items-center justify-center pt-0 pb-14 pr-0 pl-2 max-w-full text-center text-5xl text-white-200 font-small-text ${className}`}
    >
      <div className="w-full flex flex-col items-start justify-start gap-4 max-w-full "> {/* Debugging border */}
        {/* Tokenomics Section */}
        <div className="flex flex-col items-center justify-center text-center text-white">
  <h2 className="text-lg sm:text-2xl font-semibold">Tokenomics</h2>
  <div className="flex flex-col items-center">
    <p>Our token distribution is designed to ensure the success and longevity of the Lambachini ecosystem.</p>
    <p>- **5% Team Tokens**: Reserved for the dedicated team making Lambachini a reality.</p>
    <p>- **10% Marketing Tokens**: Allocated for promoting the brand and growing our community.</p>
    <p>- **45% Liquidity Tokens**: Reserved to match with pre-sale funds for initial liquidity.</p>
    <p>- **40% Presale Tokens**: Reserved for a swift and stealthy presale in preparation for our launch</p>
  </div>
  <br />
  <img className="w-full h-auto" loading="lazy" alt="" src="/piechart-1@2x.png" />
  <br />
  <h2 className="text-lg sm:text-2xl font-semibold">Uses for Lambachini Tokens:</h2>
  <div className="flex flex-col items-center">
    <p>- Access to exclusive parties</p>
    <p>- Entry to luxurious tourist trips</p>
    <p>- And much more!</p>
  </div>
</div>

        {/* Roadmap Section */}
        <div className="flex flex-col items-start justify-start gap-6 text-4xl leading-tight font-bold border border-teal-500">
        <h2 className="text-inherit font-inherit sm:text-5xl sm:leading-tight">
          Roadmap to Legendary Parties
        </h2>
        <br></br>
        <div className="h-3/5 md:h-96 overflow-hidden relative border border-brown-500"> {/* Adjusted height for responsiveness */}
          <img
            className="h-full w-full object-cover"
            loading="lazy"
            alt=""
            src="/untitled-1-1@2x.png"
          />
        </div>
      </div>
    </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
