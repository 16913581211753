import { useCallback } from "react";
import PropTypes from "prop-types";

const FrameComponent = ({ className = "" }) => {
  const onButton1Click = useCallback(() => {
    window.open("https://app.hel.io/pay/665ddb344c35821d75545cf1");
  }, []);

  return (
    <section
      className={`self-stretch flex flex-col items-start justify-start gap-[23.5px] max-w-full text-center text-21xl text-crimson-200 font-small-text ${className}`}
    >
      <div className="self-stretch flex flex-col items-end justify-start gap-[47.5px] max-w-full mq750:gap-[24px]">
        <div className="self-stretch flex flex-row items-start justify-center py-0 pr-5 pl-6 box-border max-w-full">
          <div className="w-[1113px] flex flex-col items-start justify-start gap-[24px] max-w-full">
            <h2 className="m-0 self-stretch relative text-inherit leading-[110%] font-semibold font-inherit mq450:text-5xl mq450:leading-[26px] mq1050:text-13xl mq1050:leading-[35px]">
              Join the Lambachini Party!
            </h2>
            <div className="self-stretch relative text-5xl leading-[150%] text-white mq450:text-lgi mq450:leading-[29px]">
              <p className="m-0">
                Don't miss out on the most exciting memecoin experience. Join
                the Lambachini revolution today and be part of our legendary
                parties and luxurious events. Buy Lambachini tokens now and
                secure your spot in the next big event!
              </p>
              <p className="m-0">&nbsp;</p>
              <p className="m-0">
                Don’t miss out on this once in a lifetime opportunity! All
                presale purchasers will receive an NFT that will serve as a
                ticket too one of our VIP events or tours of your choice!
              </p>
              <p className="m-0">&nbsp;</p>
              <p className="m-0">
                Our team is currently hard at work too provide a list of our
                potential future tours! From beach vacations, to exotic 18+
                tours where the limit literally depends on you!
              </p>
              <p className="m-0">&nbsp;</p>
              <p className="m-0">
                Our Lambachini crew will be sure too record any entertainment so
                we can potentially turn you into a meme!
              </p>
            </div>
            <div className="self-stretch flex flex-row items-start justify-center py-0 px-5">
              <button
                className="cursor-pointer [border:none] py-3.5 px-6 bg-crimson-200 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-lg flex flex-row items-start justify-start whitespace-nowrap hover:bg-crimson-100"
                onClick={onButton1Click}
              >
                <div className="relative text-base leading-[150%] font-medium font-small-text text-white text-left">
                  Buy Presale Now!
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-crimson-200" />
      </div>
      <div className="self-stretch flex flex-row items-start justify-center py-0 px-5">
        <img
          className="h-[145px] w-[164px] relative object-cover"
          alt=""
          src="/logo-1@2x.png"
        />
      </div>
      <div className="self-stretch flex flex-row items-start justify-center py-0 px-5">
      <div className="flex flex-row items-start justify-start gap-[8px]">
        <a href="https://t.me/lambachini" target="_blank" rel="noopener noreferrer"> {/* Example link for the first button */}
          <img
            className="h-10 w-10 relative rounded object-cover min-h-[40px]"
            loading="lazy"
            alt=""
            src="/telegram.png"
          />
        </a>
        <a href="https://www.instagram.com/lambachini_solana/" target="_blank" rel="noopener noreferrer"> {/* Example link for the second button */}
          <img
            className="h-10 w-10 relative rounded object-cover min-h-[40px]"
            alt=""
            src="/insta.png"
          />
        </a>
        <a href="https://x.com/Lambachini" target="_blank" rel="noopener noreferrer"> {/* Example link for the second button */}
          <img
            className="h-10 w-10 relative rounded object-cover min-h-[40px]"
            alt=""
            src="/twitter.png"
          />
        </a>
      </div>
    </div>
    </section>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
