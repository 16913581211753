import { useCallback } from "react";
import FrameComponent2 from "../components/frame-component2";
import FrameComponent1 from "../components/frame-component1";
import FrameComponent from "../components/frame-component";

const GalileoDesign = () => {
  const onButtonClick = useCallback(() => {
    window.open("https://app.hel.io/pay/665ddb344c35821d75545cf1");
  }, []);

  return (
    <div className="w-full relative bg-black overflow-hidden flex flex-col items-end justify-start pt-[17px] pb-4 pr-[82px] pl-[77px] box-border gap-[24px] leading-[normal] tracking-[normal] mq750:pl-[38px] mq750:pr-[41px] mq750:box-border">
      <FrameComponent2 />
      <section className="self-stretch flex flex-row items-start justify-center pt-0 pb-[54px] pr-0 pl-[5px] box-border max-w-full text-center text-21xl text-whitesmoke-200 font-small-text mq750:flex-col mq750:items-center mq750:pb-[35px] mq750:box-border">
        <div className="w-[1035px] rounded-lg flex flex-row flex-wrap items-center justify-center py-0 px-5 box-border bg-[url('/public/net-payment-collection@3x.png')] bg-cover bg-no-repeat bg-[top] max-w-full mq750:w-full">
          <div className="w-[601px] flex flex-col items-center justify-center py-[84px] px-0 box-border gap-[24px] max-w-full shrink-0 mq750:py-[55px] mq750:w-full">
            <i className="self-stretch relative leading-[110%] italic font-semibold mq450:text-5xl mq450:leading-[26px] mq1050:text-13xl mq1050:leading-[35px]">
              Join the Lambachini Revolution!
            </i>
            <div className="self-stretch h-[108px] relative text-5xl leading-[150%] text-whitesmoke-100 flex items-center mq450:text-lgi mq450:leading-[29px]">
              <span>
                <p className="m-0">
                  Discover the most exciting memecoin on the market and be part
                  of legendary parties around the world.
                </p>
              </span>
            </div>
            <button
              className="cursor-pointer [border:none] py-[15.5px] px-9 bg-crimson-400 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-lg flex flex-row items-center justify-center whitespace-nowrap hover:bg-crimson-300"
              onClick={onButtonClick}
            >
              <div className="relative text-base leading-[150%] font-medium font-small-text text-white text-left inline-block min-w-[66px]">{`Pre-Sale `}</div>
            </button>
          </div>
        </div>
      </section>
      <section className="self-stretch flex flex-row items-start justify-center py-0 pr-0 pl-[5px] box-border max-w-full text-center text-21xl text-whitesmoke-100 font-small-text mq750:flex-col mq750:items-center mq750:box-border">
        <div className="w-[1035px] flex flex-col items-start justify-start gap-[24px] max-w-full mq750:w-full">
          <h2 className="m-0 self-stretch relative text-inherit leading-[110%] font-semibold font-inherit mq450:text-5xl mq450:leading-[26px] mq1050:text-13xl mq1050:leading-[35px]">
            What is Lambachini?
          </h2>
          <br></br>
          
          <div className="self-stretch h-[180px] relative text-5xl leading-[150%] text-whitesmoke-200 flex items-center mq450:text-lgi mq450:leading-[29px]">
            <span>
              <p className="m-0">
                Lambachini is not just a memecoin; it's a lifestyle. Led by the
                stylish and adventurous LambaCat, our mission is to create
                unforgettable experiences through extravagant parties in exotic
                locations, accessible only with Lambachini tokens. Be part of a
                community that values fun, luxury, and exclusivity.
              </p>
            </span>
          </div>
          <br></br>
          <br></br>
        </div>
      </section>
      <section className="self-stretch flex flex-row items-start justify-end pt-0 pb-5 pr-[120px] pl-[125px] box-border max-w-full lg:pl-[62px] lg:pr-[60px] lg:box-border mq750:flex-col mq750:items-center mq750:box-border">
        <img
          className="self-stretch h-auto max-w-full relative object-contain mq750:w-full"
          loading="lazy"
          alt=""
          src="/merchandise-1@2x.png"
        />
      </section>
      <section className="w-[1255.5px] flex flex-row items-start justify-center pt-0 px-0 pb-[26px] box-border max-w-full text-center text-21xl text-whitesmoke-200 font-small-text mq750:flex-col mq750:items-center mq750:box-border">
        <div
          className="w-[1055px] flex flex-col items-start justify-start pt-0 px-0 pb-px box-border gap-[31px] max-w-full mq750:w-full"
          data-scroll-to="container1"
        >
          <div className="self-stretch h-[67px] relative leading-[110%] font-semibold inline-block shrink-0 mq450:text-5xl mq450:leading-[26px] mq1050:text-13xl mq1050:leading-[35px]">{`Our Mission & Goals`}</div>
          <div className="self-stretch relative text-5xl leading-[150%] text-whitesmoke-100 mq450:text-lgi mq450:leading-[29px]">
            <p className="m-0">
              At Lambachini, we aim to revolutionize the way people party. Our
              primary goal is to host the most extravagant and exclusive events
              around the world, where Lambachini holders can spend their tokens.
              From VIP areas to 18+ tours in exotic locations, our parties will
              offer unparalleled experiences.
            </p>
          </div>
          <br></br>
          <img
            className="self-stretch h-auto max-w-full relative object-contain mq750:w-full"
            loading="lazy"
            alt=""
            src="/-31a73b4cf4334b3eab9e0ce2679f2333-1@2x.png"
          />
         
        </div>
        
      </section>
      <FrameComponent1 />
      <FrameComponent />
    </div>
  );
};

export default GalileoDesign;
