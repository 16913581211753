import { useState, useCallback } from "react";
import PropTypes from "prop-types";

const HeaderNavigation = ({ className = "" }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const onMissionsGoalsClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='container1']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
    setDrawerOpen(false);
  }, []);

  const onTokenomicsText1Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='container']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
    setDrawerOpen(false);
  }, []);

  const onButtonClick = useCallback(() => {
    window.open("https://app.hel.io/pay/665ddb344c35821d75545cf1");
  }, []);

  const toggleDrawer = useCallback(() => {
    setDrawerOpen((prev) => !prev);
  }, []);

  return (
    <div
  className={`w-full bg-black overflow-hidden shrink-0 flex items-center justify-between py-2.5 px-5 box-border max-w-full text-left text-xl text-crimson-200 font-small-text ${className}`}
>
  <img
    className="h-[145px] w-[164px] relative object-cover cursor-pointer mx-4" 
    loading="lazy"
    alt=""
    src="/logo-1@2x.png"
    onClick={toggleDrawer}
  />
  
      {drawerOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex flex-col items-center justify-center z-50">
          <div
            className="absolute top-5 right-5 cursor-pointer text-white text-3xl"
            onClick={toggleDrawer}
          >
            &times;
          </div>
          <div
            className="relative leading-[150%] font-medium whitespace-nowrap cursor-pointer mb-4 text-white text-2xl"
            onClick={onMissionsGoalsClick}
          >
            Missions & Goals
          </div>
          <div
            className="relative leading-[150%] font-medium inline-block min-w-[115px] cursor-pointer mb-4 text-white text-2xl"
            onClick={onTokenomicsText1Click}
          >
            Tokenomics
          </div>
        </div>
      )}
      <div className="self-stretch flex flex-row items-start justify-center py-0 px-5">
      <div className="flex flex-row items-start justify-start gap-[8px]">
        <a href="https://t.me/lambachini" target="_blank" rel="noopener noreferrer"> {/* Example link for the first button */}
          <img
            className="h-10 w-10 relative rounded object-cover min-h-[40px]"
            loading="lazy"
            alt=""
            src="/telegram.png"
          />
        </a>
        <a href="https://www.instagram.com/lambachini_solana/" target="_blank" rel="noopener noreferrer"> {/* Example link for the second button */}
          <img
            className="h-10 w-10 relative rounded object-cover min-h-[40px]"
            alt=""
            src="/insta.png"
          />
        </a>
        <a href="https://x.com/Lambachini" target="_blank" rel="noopener noreferrer"> {/* Example link for the second button */}
          <img
            className="h-10 w-10 relative rounded object-cover min-h-[40px]"
            alt=""
            src="/twitter.png"
          />
        </a>
      </div>
    </div>
    </div>
  );
};

HeaderNavigation.propTypes = {
  className: PropTypes.string,
};

export default HeaderNavigation;
